import React from "react"
import { Link, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"
import ArticleLargeCard from "../components/article/article-large-card"

const ArticleCards = styled.div`
    ${tw`flex flex-wrap -mx-3 lg:-mx-6 -mt-6`}
`

const Pagination = styled.nav`
    ${tw`text-center text-white mt-6 py-4`}
`

const PaginationList = styled.ul`
    ${tw`list-none inline-block`}
`

const PaginationLink = styled.li`
    ${tw`float-left p-2`}
`

const StyledLink = styled(Link)`
    ${tw`w-full h-full block w-12 h-12 p-2 bg-psb-blue-100 rounded-lg hover:bg-psb-blue-300
        transition-colors duration-300 focus:outline-none focus:bg-psb-pink-200 text-white text-lg font-bold`}
`

const ArticlesTemplate = ({ data, pageContext, location }) => {
    const articles = data.allMdx.edges
    const { currentPage, numberOfPages } = pageContext
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === numberOfPages
    const previousPage = currentPage - 1 === 1 ? "/articles/" : `/articles/${(currentPage - 1).toString()}`
    const nextPage = `/articles/${(currentPage + 1).toString()}`

    return (
        <Layout>
            <SEO
                title="Articles"
                pathname={location.pathname}
            />
            <Hero title="Articles" description="" image={data.hero.childImageSharp.fluid} />
            <Section>
                <ArticleCards>
                    {articles.map(({ node }) => (
                        <ArticleLargeCard
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                            categories={node.frontmatter.categories}
                            tags={node.frontmatter.tags}
                            featuredImage={node.frontmatter.featuredImage.childImageSharp.fluid}
                            slug={node.fields.slug} />
                    ))}
                </ArticleCards>
                <Pagination>
                    <PaginationList>
                        {!isFirstPage && (
                            <PaginationLink>
                                <button>
                                    <StyledLink
                                        to={previousPage}
                                        rel="prev"
                                        activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                        &lt;
                                    </StyledLink>
                                </button>
                            </PaginationLink>
                        )}
                        {Array.from({ length: numberOfPages }, (_, i) => (
                            <PaginationLink>
                                <button>
                                    <StyledLink
                                        key={`pagination-number${i + 1}`}
                                        to={`/articles/${ i === 0 ? "" : i + 1}`}
                                        activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                        {i + 1}
                                    </StyledLink>
                                </button>
                            </PaginationLink>
                        ))}
                        {!isLastPage && (
                            <PaginationLink>
                                <button>
                                    <StyledLink
                                        to={nextPage}
                                        rel="next"
                                        activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                        &gt;
                                    </StyledLink>
                                </button>
                            </PaginationLink>
                        )}
                    </PaginationList>
                </Pagination>
            </Section>
        </Layout>
    )
}

export default ArticlesTemplate

export const articlesPageListQuery = graphql`
    query articlePageListQuery($skip: Int!, $limit: Int!) {
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
            limit: $limit
            skip: $skip    
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                        categories
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1200) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        hero: file(relativePath: { eq: "mirage-searching_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 400 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`